import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/javascript-console",
  "date": "2016-05-16",
  "title": "JAVASCRIPT CONSOLE",
  "author": "admin",
  "tags": ["development", "javascript"],
  "featuredImage": "feature.jpg",
  "excerpt": "The JavaScript Console or the Console Object provides access to the browser’s debugging console. Chrome’s debugging console is DevTools Console and for Firefox it is Web Console. It is available in browser’s Window scope"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h2>{`Basic Logging`}</h2>
    <p>{`Let’s start by looking at the basic logging methods.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`console.log('This is log');
console.warn('This is a warning');
console.error('This is an error');
console.info('This is info');
console.debug('This is debug');
`}</code></pre>
    <p>{`This will log the following messages in the console:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-16-javascript-console/logs.jpg",
        "alt": "Logs"
      }}></img></p>
    <Message type="info" title="console.log vs console.debug" content="In Chrome, `console.debug` will show in blue but `console.log` will be shown in black. The color is the only difference as far as these two methods concerned." mdxType="Message" />
    <p>{`You can filter the log messages by selecting the dropdown above the console window:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-16-javascript-console/logs.jpg",
        "alt": "filter"
      }}></img></p>
    <p>{`You can pass in values as a comma delimited array as well. These values will be logged out separated by a space.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`console.log('This', 'is',  1, 'log');
`}</code></pre>
    <p>{`This will be logged out like a usual message:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-16-javascript-console/args.jpg",
        "alt": "Arguments"
      }}></img></p>
    <h2>{`String Substitution`}</h2>
    <p>{`If the first argument starts with a string, we can use string substitution like in C language.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`console.log('The %s has %d moon(s)', 'Earth', 1, {name: 'Moon'});
`}</code></pre>
    <p>{`The result in console:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-16-javascript-console/subs.jpg",
        "alt": "Substitution"
      }}></img></p>
    <h2>{`CSS Output`}</h2>
    <p>{`By prefixing `}<inlineCode parentName="p">{`%c`}</inlineCode>{` to a string, we can print CSS into the console!`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`console.log(
  'This looks %cfansy!',
  'color: hotpink; font-size: 30px; border:1px solid black; padding: 2px'
);
`}</code></pre>
    <p>{`The result in console:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-16-javascript-console/css.jpg",
        "alt": "CSS"
      }}></img></p>
    <h2>{`Grouping and Nesting`}</h2>
    <p>{`We can group log output into collapsable hierarchies using `}<inlineCode parentName="p">{`console.group()`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`console.groupCollapsed('Fruits');
console.groupCollapsed('Green');
console.log('Apples');
console.log('Pears');
console.groupCollapsed('Yellow');
console.log('Bananas');
console.log('Pineapple');
console.groupEnd();
`}</code></pre>
    <p>{`The result in console:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-16-javascript-console/nesting.jpg",
        "alt": "Nesting"
      }}></img></p>
    <h2>{`Assertion`}</h2>
    <p>{`We can use `}<inlineCode parentName="p">{`console.assert`}</inlineCode>{`, which is syntactic sugar for logging an error to the console when a given condition is not met.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var something;
 
console.assert(something, 'something is not defined!');
`}</code></pre>
    <p>{`The result in console:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-16-javascript-console/assert.jpg",
        "alt": "Assertion"
      }}></img></p>
    <h2>{`Counting Events`}</h2>
    <p>{`By using `}<inlineCode parentName="p">{`console.count`}</inlineCode>{` we can count the number of times something was logged.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`for(var i = 0; i < 10; i++) {
  if(i < 6) {
    console.count('less than 6');
  } else {
    console.count('greater than 6');
  }
}
`}</code></pre>
    <p>{`The result in console:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-16-javascript-console/count.jpg",
        "alt": "Count"
      }}></img></p>
    <h2>{`Timing Data`}</h2>
    <p>{`By using `}<inlineCode parentName="p">{`console.time`}</inlineCode>{` with `}<inlineCode parentName="p">{`console.timeEnd`}</inlineCode>{` to get accurate timings of operations in javascript.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var i = 0;
 
console.time('looping');
 
while(i > 1000000) {
  i++;
}

console.timeEnd('looping');
`}</code></pre>
    <p>{`The result in console:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-16-javascript-console/loop.jpg",
        "alt": "Louping"
      }}></img></p>
    <h2>{`Tabular Data`}</h2>
    <p>{`Using `}<inlineCode parentName="p">{`console.table`}</inlineCode>{` to render arrays and objects in a tabular format.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var fruits = [
  { name: 'Apple', color: 'Red'},
  { name: 'Pear', color: 'Green'},
  { name: 'Banana', color: 'Yellow'}
];
 
console.table(fruits);
`}</code></pre>
    <p>{`The result in console:`}</p>
    <p><img parentName="p" {...{
        "src": "/images/2016-05-16-javascript-console/tabular.jpg",
        "alt": "Tabular"
      }}></img></p>
    <h2>{`Conclusion`}</h2>
    <p>{`These console techniques will definitely help you to make your console logs look more interesting.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      